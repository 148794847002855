import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Carousel3d from 'vue-carousel-3d';
// import ScrollMagic from 'scrollmagic';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'; // Optional - for debugging
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'; 

Vue.use(Vuetify);
Vue.use(Carousel3d);
// Vue.use(ScrollMagic);
export default new Vuetify({
    theme: {
        // options: {
        //     customProperties: true,
        //   },
        //   fonts: {
        //     family: 'Playfair serif',
        //   },
        dark: false,
        themes: {
            light: {
                background: '#FFC93C',
                primary: '#F9C5D5',
                secondary: '#F999B7',
                accent: '#F2789F',
                surface:'#F2789F',
                error: '#B00020',
                info: '#FEE3EC',
                success: '#FBA1B7',
                warning: '#FEE3EC',
            },
            dark: {
                background:'#000000',
                primary: '#000000',
                secondary: '#F9C5D5',
                accent: '#F999B7',
                surface:'#F2789F',
                error: '#B00020',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
        },
    
    },
});
