import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
// import vuetify from '@/plugins/vuetify'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user:null,
    theme:null,
    roles:null,
    editProd:null,
    editOrder:null,
    cart:null,
    orderInfos:null,
    orderDetails:null
  },
  getters: {
    user:(state)=>{
        return state.user;
    },
    theme:(state)=>{
      return state.theme;
    },
    roles:(state)=>{
      return state.roles;
    },
    editProd:(state)=>{
      return state.editProd;
    },
    editOrder:(state)=>{
      return state.editOrder;
    },
    cart:(state)=>{
      return state.cart;
    },
    orderInfos:(state)=>{
      return state.orderInfos;
    },
    orderDetails:(state)=>{
      return state.orderDetails;
  },
  },
  mutations: {
    user(state,user){
      state.user = user;
    },
    theme(state,theme){
      state.theme = theme;
    },
    roles(state,roles){
      state.roles = roles;
    },
    editProd(state,editProd){
      state.editProd = editProd;
    },
    editOrder(state,editOrder){
      state.editOrder = editOrder;
    },
    cart(state,cart){
      state.cart = cart;
    },
    orderInfos(state,roles){
      state.roles = roles;
    },
    orderDetails(state,details){
      state.orderDetails = details;
    },
  },
  actions: {
    user(context, user) {
      context.commit('user', user);
    },
    theme(context, theme) {
      context.commit('theme', theme);
    },
    roles(context, roles) {
      context.commit('roles', roles);
    },
    editProd(context, editProd) {
      context.commit('editProd', editProd);
    },
    editOrder(context, editOrder) {
      context.commit('editOrder', editOrder);
    },
    cart(context, cart) {
      context.commit('cart', cart);
    },
    orderInfos(context, orderInfos) {
      context.commit('orderInfos', orderInfos);
    },
    orderDetails(context, orderDetails) {
      context.commit('orderDetails', orderDetails);
    }
  },
  modules: {
  }
})
