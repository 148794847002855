<template>
    <v-card  class="mx-auto" color="info" :max-width="$vuetify.breakpoint.mdAndDown ? 180 : 250">
      <v-img @click="productPage()" contain :aspect-ratio="1" :src="`${link}${products.pic}`" >
        <v-overlay
          :absolute="true"
          :value="products.out_of_stock != 0"
        >
          <div class="d-flex text-h4 white--text font-weight-black">
            {{ $t('product.card.out') }}
          </div>
        </v-overlay>
      </v-img>
      <v-card-text class="pt-2 d-flex flex-column align-center" style="position: relative;">
        <v-btn v-if="!$vuetify.breakpoint.mdAndDown" absolute color="background" class="white--text" fab large right top @click="addToCart(`addToCart`)">
          <v-icon color="white">mdi-cart-outline</v-icon>
        </v-btn>
        <v-btn absolute v-if="$vuetify.breakpoint.mdAndDown" color="background" class="white--text" fab small right top @click="addToCart(`addToCart`)">
          <v-icon color="white">mdi-cart-outline</v-icon>
        </v-btn>

        <v-row class="justify-center align-center mt-1" @click="productPage()">
          <v-col cols="12" class="text-center">
            <div v-if="(products.discount > 0 && product.timer_start_date == null)||(activateDiscount && products.discount > 0)" class="background--text mr-5 mx-auto">
              {{$t("product.card.discount")}} -{{ Math.round((product.discount * 100) / product.price,2) }}%
            </div>
            <div class="font-weight-bold mr-5 mx-auto">
              {{ products.name }}
            </div>
            <div v-if="products.out_of_stock != 0" class=" mx-auto font-weight-bold background--text mr-5">
              rupture de stock
            </div>
            <div v-else class="mx-auto">
                <span v-if="(product.discount > 0 && activateDiscount) || (product.discount > 0 && product.timer_start_date == null)" class="text-decoration-line-through font-weight-bold background--text mr-5">{{ product.price }}DA</span>
                <span v-if="(product.discount > 0 && activateDiscount) || (product.discount > 0 && product.timer_start_date == null)" class="font-weight-bold background--text mr-5">{{( product.price - product.discount)}} DA</span>
                <span  v-else class="font-weight-bold background--text mr-5">{{product.price}} DA</span>
              <!-- {{products.price + $t("product.card.da") }} -->
            </div>
          </v-col>
        </v-row>

        <v-list color="info" dense v-if="filteredKeys">
          <v-list-item v-for="(key, index) in filteredKeys" :key="index">
            <v-row>
              <v-col cols="12">
                <v-list-item-content :class="{ 'pink--text': sortBy === key }">
                    {{ key == "Discount" ? "Remise": key  }}: 
                <!-- </v-list-item-content>
                <v-list-item-content  class="align-end" :class="{ 'pink--text': sortBy === key }"> -->
                    {{ " " + products[key.toLowerCase()] }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
</template>

<script>
import { computed } from 'vue';
import store from '../store';
import axios from 'axios';
  export default {
  data () {
    return {
      link:'https://api.lenara.net/',
      myCart:new Array(),
      product:{
        id: "",
        name: "",
        price: "",
        description: "",
        quantity: 0,
        discount: 0,
        pic: "",
        brand: {
            name: "",
            icon: "",
        },
        product_type: {
            name: "",
            pic: "",
        }
      },
    }
  },
  setup() {
    const cart = computed(() =>store.getters.cart);
    return {
        cart,
    };
  },
  computed:{
    activateDiscount(){
            if(this.product.timer_start_date && this.product.timer_end_date){
                const now = new Date();
                const formattedNow = new Date(now.getTime()+ 60 * 60 * 1000);
                
                // console.log("start date :" + this.product.timer_start_date)
                const startDate = new Date(this.product.timer_start_date).getTime();
                // console.log("start Date time:"+ startDate)
                const adjustedTime = new Date(formattedNow.toISOString()); 
                // console.log("now time:"+ adjustedTime)
                const start = startDate - adjustedTime.getTime();
                // console.log("start value: "+start)
                // console.log("start Date:"+ this.product.timer_start_date)
                
                if(start <= 0){
                    return true
                }else{
                    return false;
                }
                // return true
            }else{
                return false;
            }
            
        }
  },
  props: {
      products: Object,
      cardWidth: {
        type: String,
        default: "280px" // Default width
      },
      filteredKeys: Array,
      sortBy: String
    },
    async mounted() {
        try{
            // this.images=[];

            let res = await axios.get(`search/getProduct/${this.products.id}`);
            this.product = res.data;
            console.log(this.product.timer_start_date + ' ' + this.activateDiscount)
            
            if(this.cart != null){
                this.myCart = this.cart ;
            }else {
                this.myCart = [];
            }
            // if(this.product.productPictures.length > 0){
            //     this.imgView = `https://api.lenara.net/${this.product.productPictures[0].pic}`
            // }else{
            //     this.imgView = `https://api.lenara.net/${this.product.pic}`
            // }
            
            // for(let img of this.product.productPictures){
            //     this.images.push(`https://api.lenara.net/${img.pic.replace(/\\/g,'/')}`)
            // }
        }catch(e){
          throw new Error("server side error cant get products");
        }
    },
    methods:{
      productPage(){
        // this.$router.push(`/${this.$i18n.locale}/product/${this.product.id}`);
        window.location.href = `https://lenara.net/${this.$i18n.locale}/product/${this.products.id}`
      },
      addToCart(command){
            let duplicated = this.myCart.find((e)=> e.id == this.products.id);
            if(duplicated || this.products.out_of_stock != 0){
              this.$root.$emit("show_error_alert",'this product is already in cart or out of stock')
            }else{
                this.myCart.push({
                    id:this.products.id,
                    name:this.products.name,
                    price:this.products.price,
                    color:'non',
                    brand:this.product.brand.name,
                    quantity:1,
                    pic:this.products.pic,
                    discount:this.products.discount,
                    timer_start_date:this.product.timer_start_date,
                    timer_end_date:this.product.timer_end_date
                });
                store.dispatch('cart',this.myCart);
                if(command == 'order'){
                    this.$router.push(`/${this.$i18n.locale}/buy`);
                }
            }
        },
    }
  }
</script>