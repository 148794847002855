<template>
    <v-footer app absolute dark padless>
      <v-card flat tile width="100%" class="accent text-center">
        <v-card-text>
          <v-btn v-for="(icon,i) in icons" :key="i" :href="icon.link" target="_blank" class="mx-4 white--text" icon>
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
          <v-btn href="https://www.tiktok.com/@lenara_batna_?_t=8mWFvjYL2pC&_r=1" class="mx-4 white--text " icon>
            <img :src="require('../assets/tiktok.png')" alt="icon" class="icon-image" height="20" width="20">
          </v-btn>
        </v-card-text>
  
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" md="4">
              <v-card-title class="text-center text-md-left">
                <!-- Why Us: -->
                {{ $t("footer.firstPart.title") }}
              </v-card-title>
              <v-card-text class="text-h6 text-center text-md-left">
                <!-- Experience effortless beauty with LEANARA Press-On Nails, High-quality designs for every style. Easy application, long-lasting wear. -->
                {{ $t("footer.firstPart.paragraph") }}
              </v-card-text>
            </v-col>
            <v-col cols="4" md="4" v-if="$vuetify.breakpoint.smAndDown" >
              <v-divider thickness="500" width="200" color="white"></v-divider>
            </v-col>
            
            <v-col cols="12" md="4">
              <v-card-title class="text-center text-md-left">
                <!-- Contact: -->
                {{ $t("footer.secondPart.contacts") }}
              </v-card-title>
              <v-card-text class="text-center text-md-left">
                <ul style="list-style-type: none;">
                  <li class="text-h6">{{ $t("footer.secondPart.phone") }}: 0669266876/0553907107</li>
                  <li class="text-h6">{{ $t("footer.secondPart.e-mail") }}:lenara-service@lenara.net</li>
                  <li class="text-h6">{{ $t("footer.secondPart.address") }}:Parc a Fourage, lycée Salah Eddine à côté de l'école Saba 2, Batna.</li>
                </ul>
              </v-card-text>
            </v-col>
            <v-col cols="4" md="4" v-if="$vuetify.breakpoint.smAndDown" >
              <v-divider thickness="500" width="200" color="white"></v-divider>
            </v-col>
            <v-col cols="12" md="4">
              <v-card-title class="text-center text-md-left">
                {{ $t("footer.map") }}
              </v-card-title>
              <v-card-text class="text-center text-md-left">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3246.317588519575!2d6.182804574564013!3d35.545857937400534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f411333c9af7a7%3A0x86668ff1385a4ae8!2sLeNaRa%20health%20and%20beauty!5e0!3m2!1sen!2sdz!4v1709659652887!5m2!1sen!2sdz" width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
  
        <v-divider></v-divider>
  
        <v-card-text class="white--text text-center">
          {{ new Date().getFullYear() }} — <strong>LENARA</strong>
          <strong><br> {{ $t("footer.lowerPart.developer") }} {{ $t("footer.lowerPart.email") }}: ousslar50@gmail.com {{ $t("footer.lowerPart.phone") }}:0665174039</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </template>

<script>
// import { gampApi } from 'vue2-google-maps'
// import googleMap from './map/googleMap.vue'
export default {
    name:'FooterPage',
    component:{
        // googleMap
    },
    data: () => ({
        // center: {lat: 10.0, lng: 10.0},
        // markers: [{
        //     position: {lat: 10.0, lng: 10.0}
        // }, {
        //     position: {lat: 11.0, lng: 11.0}
        // }],
        icons: [
            {icon:'mdi-facebook',link:'https://www.facebook.com/Boutique.LeNaRa.05?mibextid=ZbWKwL'},
            {icon:'mdi-instagram',link:'https://www.instagram.com/lenara_batna_/?igsh=MWo3djBndjJkaDdzZw%3D%3D'},
        ],
    }),
    mounted(){
        this.center = {lat:35.551007255836176,lng:6.166990584341131}
    }
}
</script>
<style>
/* .v-footer {
    color
  position: fixed;
  bottom: 0;
  width: 100%;
} */
</style>