<template>
  <v-app id="app">
    <v-navigation-drawer app height="100vh" class="accent" v-model="drawer" temporary>
        <v-list-item dark>
            <v-list-item-avatar class="accent">
                <v-img :src="`${link}${profilePic}`"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title dark>{{userName}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dark dense>
            <v-list-item  v-for="item in items" :key="item.title" :to="`${item.link}`" link>
            <v-list-item-icon>
                <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list>
    </v-navigation-drawer>
    <HeaderBar @openD="openDrawer"></HeaderBar>
    <v-main>
        <SuccessMsg ref="sucMsg" :su_msg="message"/>
        <ErrorMsg ref="erMsg" :er_msg="message"/>
        <InformationMsg ref="infMsg" :inf_msg="message"/>
        <WarningMsg ref="warnMsg" :wa_msg="message"/>
        <router-view/>
    </v-main>
    <FooterBar/>
  </v-app>
</template>
<script>
import WarningMsg from '@/components/warningAlert.vue'
import SuccessMsg from '@/components/successAlert.vue'
import ErrorMsg from '@/components/errorAlert.vue'
import InformationMsg from '@/components/infoAlert.vue'
import HeaderBar from '@/components/header.vue'
import store from './store';
import FooterBar from '@/components/footer.vue'
import { computed } from 'vue';
export default{
  components:{
    HeaderBar,
    SuccessMsg,
    WarningMsg,
    ErrorMsg,
    InformationMsg,
    FooterBar
  },
  setup() {
    const user = computed(() =>store.getters.user);
    return {
        user,
    };
  },
  data() {
    return {
      message:"",
      showError:false,
      showSuccess:false,
      showInfo:false,
      showWarning:false,
      drawer:false,
      items: [
        { title: this.$t('sidebar.profile'), icon:'mdi-account-cog-outline',link:`/${this.$i18n.locale}/profile`},
        { title: this.$t('sidebar.myOrders'), icon:'mdi-package-variant',link:`/${this.$i18n.locale}/orders`},
        { title: this.$t('sidebar.security'), icon:'mdi-shield-account-outline',link:`/${this.$i18n.locale}/security`},
        { title: this.$t('sidebar.clientOrders'), icon:'mdi-package-variant-closed',link:`/${this.$i18n.locale}/clientOrders`},
        { title: this.$t('sidebar.delivery'), icon:'mdi-truck-delivery-outline',link:`/${this.$i18n.locale}/delivery`},
        { title: this.$t('sidebar.yalidine'), icon:'mdi-storefront-outline',link:`/${this.$i18n.locale}/yalidine`},
        { title: this.$t('sidebar.employees'), icon:'mdi-face-agent',link:`/${this.$i18n.locale}/manageSellers`},
        { title: this.$t('sidebar.Products'), icon:'mdi-library-shelves',link:`/${this.$i18n.locale}/manageProducts`},
        { title: this.$t('sidebar.ProductTypes'), icon:'mdi-format-list-bulleted-type',link:`/${this.$i18n.locale}/manageTypes`},
        { title: this.$t('sidebar.productBrands'), icon:'mdi-apple',link:`/${this.$i18n.locale}/manageBrands`},
        { title: this.$t('sidebar.news'), icon:'mdi-bullhorn-variant-outline',link:`/${this.$i18n.locale}/news`},
        { title: this.$t('sidebar.dashboard'), icon:'mdi-finance',link:`/${this.$i18n.locale}/stats`},
        { title: this.$t('sidebar.coupons'), icon:'mdi-ticket-percent-outline',link:`/${this.$i18n.locale}/manageCoupons`},
        { title: this.$t('sidebar.blackList'), icon:'mdi-account-cancel-outline',link:`/${this.$i18n.locale}/blackList`},
        //{ title: this.$t('sidebar.Store'), icon:'mdi-storefront-outline',link:`/${this.$i18n.locale}/store`},
      ],
      userName:'',
      profilePic:'',
      link:'https://api.lenara.net/',
    }
  },
  created() {
    this.$root.$on("show_success_alert",(message)=>{
      this.message = message
      this.$refs.sucMsg.showAlert()
    })
    this.$root.$on("show_error_alert",(message)=>{
      this.message = message
      this.$refs.erMsg.showAlert()
    })
    this.$root.$on("show_info_alert",(message)=>{
      this.message = message
      this.$refs.infMsg.showAlert()
    })
    this.$root.$on("show_warn_alert",(message)=>{
      this.message = message
      this.$refs.warnMsg.showAlert()
    })
  },
  mounted(){
      if(this.user){
          this.hidden = true;
          this.userName = this.user.username;
          this.profilePic=this.user.pic;
      }else{
          this.hidden = false;
          this.userName = '';
          this.profilePic='';
      }
  },
  methods:{
    openDrawer(){
      console.log(this.drawer)
      this.drawer = !this.drawer
    }
  }
}
</script>
<style lang="scss">

.my-alert {
  z-index: 9999; /* or any high enough value */
  margin-top:64px;
  width: 400px;
}

.v-application .text-h3 {
  font-family: Playfair, serif !important;
}
.v-application .text-h4 {
  font-family: Playfair, serif !important;
}
.text-h2 {
  font-family: Playfair, serif !important;
}
#app {
  background: url('@/assets/Background/Untitled.png') no-repeat center center fixed ;
  background-size:100% 100%;
  font-family: 'Times New Roman', Times, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  min-height: 100vh;
  // position: relative;
  // speed-dial-z-index:	3;
}

.background-container {
  /* background-image: url('@/assets/background3.png'); */
  /* height: 100vh; */
  // background-color: #FFF0F5;
  /* background-position: left center; */
  /* padding-left: 5px; */
  /* background-attachment: fixed; */
  position: relative; /* Ensure position is relative */
  overflow: hidden;
  /* z-index:0; */
  /* opacity: 0.1;  */
}


.container {
  padding: 0px;
}

.tiptap{
    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td,
            th {
                min-width: 1em;
                border: 2px solid black;
                padding: 3px 5px;
                vertical-align: top;
                box-sizing: border-box;
                position: relative;

                > * {
                      margin-bottom: 0;
                    }
            }

            th {
                font-weight: bold;
                text-align: left;
                background-color: #f926f5;
            }

            .selectedCell:after {
                z-index: 2;
                position: absolute;
                content: "";
                left: 0; right: 0; top: 0; bottom: 0;
                background: rgba(200, 200, 255, 0.4);
                pointer-events: none;
            }

            .column-resize-handle {
                position: absolute;
                right: -2px;
                top: 0;
                bottom: -2px;
                width: 4px;
                background-color: #adf;
                pointer-events: none;
            }

            p {
                margin: 0;
            }
        }
} 
.tableWrapper {
        padding: 1rem 0;
        overflow-x: auto;
    }
.v-navigation-drawer {
  height:100%
}

main {
  padding-bottom: 60px; /* Adjust the value based on the height of your footer */
}

.transparent-sheet {
  background-color: transparent !important; /* Make the background transparent */
  border: 2px solid #FFC93C; /* Add black border */
}

</style>
