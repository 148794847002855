<template>
    <v-app-bar fixed prominent shrink-on-scroll elevate-on-scroll color="transparent" :class="{ 'blurred': isBlurred }">
      <v-btn class="mt-1" small fab color="accent" dark v-if="hidden" @click.stop="drawer">
        <v-icon icon color="white">mdi-account-circle-outline</v-icon>
      </v-btn>
  
      <v-btn min-height="48px" :x-large="!$vuetify.breakpoint.mdAndDown" rounded color="accent" dark class="ml-4" :to="`/${$i18n.locale}`">
        <v-img  contain max-height="45px" max-width="35px" src="../assets/logo.png"></v-img>
        <v-img v-if="!$vuetify.breakpoint.mdAndDown" contain max-height="45px" max-width="90px" src="../assets/logo2.png"></v-img>
      </v-btn>
      <v-spacer></v-spacer>
      <v-responsive width="180">
        <v-text-field v-model="keyWord" background-color="accent" rounded :label="$t('header.search')" hide-details dark solo>
          <v-btn dark x-small slot="append" @click="search()" icon>
            <!-- :to="`/${$i18n.locale}/search?name=${keyWord}`" -->
            <v-icon color="white">mdi-magnify</v-icon>
          </v-btn>
        </v-text-field>
      </v-responsive>

      <v-spacer></v-spacer>

      <v-menu offset-y >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$vuetify.breakpoint.mdAndDown" small class="mr-4 mt-1" id="menu-activator" fab color="accent" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-format-list-bulleted</v-icon>
              <v-badge v-if="BadgeCart" :content="BadgeCartLength"></v-badge>
            </v-btn>
          </template>
        <v-menu offset-x >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="$vuetify.breakpoint.mdAndDown" small class="mr-4 mt-1" id="menu-activator" fab color="accent" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-translate</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(language, i) in languages" :key="i">
              <v-list-item-title @click="changeLanguage(language.value)">{{ language.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn small class="mr-4 mt-1" :to="`/${$i18n.locale}/logsub`" v-if="!hidden" fab color="accent">
          <v-icon color="white">mdi-login</v-icon>
        </v-btn>

        <v-btn small class="mr-4 mt-1" @click="disconnect" v-if="hidden" fab color="accent">
          <v-icon color="white">mdi-logout</v-icon>
        </v-btn>

        <v-btn small class="mr-4 mt-1" :to="`/${$i18n.locale}/buy`" fab color="accent">
          <v-icon color="white">mdi-cart-outline</v-icon>
          <v-badge v-if="BadgeCart" :content="BadgeCartLength"></v-badge>
        </v-btn>

        <v-badge dark bordered overlap content="7" v-if="false"> 
          <v-btn small class="mr-4 mt-1" :to="`/${$i18n.locale}/wishList`" fab color="accent">
            <v-icon dark>mdi-cart-heart</v-icon>
          </v-btn>
        </v-badge>
      </v-menu>

      <v-menu offset-y >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="!$vuetify.breakpoint.mdAndDown" small class="mr-4 mt-1" id="menu-activator" fab color="accent" v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(language, i) in languages" :key="i">
            <v-list-item-title @click="changeLanguage(language.value)">{{ language.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
  
      <v-btn v-if="!$vuetify.breakpoint.mdAndDown && !hidden" small class="mr-4 mt-1" :to="`/${$i18n.locale}/logsub`" fab color="accent">
        <v-icon color="white">mdi-login</v-icon>
      </v-btn>
  
      <v-btn v-if="!$vuetify.breakpoint.mdAndDown && hidden" small class="mr-4 mt-1" @click="disconnect" fab color="accent">
        <v-icon color="white">mdi-logout</v-icon>
      </v-btn>
  
      <v-btn v-if="!$vuetify.breakpoint.mdAndDown" small class="mr-4 mt-1" :to="`/${$i18n.locale}/buy`" fab color="accent">
        <v-icon color="white">mdi-cart-outline</v-icon>
        <v-badge v-if="BadgeCart" :content="BadgeCartLength"></v-badge>
      </v-btn>
  
      <v-badge v-if="!$vuetify.breakpoint.mdAndDown && false" dark bordered overlap content="7" > 
        <v-btn small class="mr-4 mt-1" :to="`/${$i18n.locale}/wishList`" fab color="accent">
          <v-icon dark>mdi-cart-heart</v-icon>
        </v-btn>
      </v-badge>
    </v-app-bar>
  </template>
<script>
import { computed } from 'vue';

import store from '../store';
    export default {
        setup() {
            const user = computed(() =>store.getters.user);
            const Products = computed(() =>store.getters.cart);
            return {
                Products,
                user,
            };
        },
        computed:{
            BadgeCart(){
                if(this.Products !== null){
                    return this.Products.length > 0 ? true : false;
                }else{
                    return false;
                } 
            },
            BadgeCartLength(){
                if(this.Products !== null){
                    return this.Products.length
                }else{
                    return 0;
                } 
            }
        },
        methods: {
          handleScroll() {
            this.isBlurred = window.scrollY > 50;
          },
          search(){
            const queryParams = new URLSearchParams({
                name:this.keyWord
            });
            const redirectUrl = `/${this.$i18n.locale}/search?${queryParams.toString()}`;
            this.$router.push(redirectUrl);
            this.$router.go(0);
          },
          changeLanguage(lang){
            this.$i18n.locale = lang;
            const currentPath = this.$route.fullPath;
            const newPath = `/${lang}${currentPath.replace(/^\/[a-z]{2}\b/, '')}`;
            this.$router.push({ path: newPath });
            location.reload();
          },
            drawer(){
                this.$emit('openD');
            },
            onchangeTheme(){
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            },

            disconnect(){
                localStorage.removeItem('x-auth-token');
                store.dispatch('user',null);
                window.location.href = "https://lenara.net/";
            },
        },
        name:'HeaderBar',
        components:{
        },
        data () {
            return {
              isBlurred: false,
              keyWord:"",
              showCartBadge:false,
              userName:'',
              profilePic:'',
              link:'https://api.lenara.net/',
              languages: [
                  // { title: 'English', value:'en'},
                  { title: 'français', value:'fr' },
                  { title: 'عربية', value:'ar' },
              ],
              hidden:false,
              // drawer: false,
            }
        },
        mounted(){
            if(this.user){
                this.hidden = true;
                this.userName = this.user.username;
                this.profilePic=this.user.pic;
            }else{
                this.hidden = false;
                this.userName = '';
                this.profilePic='';
            }
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
          window.removeEventListener('scroll', this.handleScroll);
        },
    }
</script>

<style>
.blurred {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.7); /* Adjust the color and opacity as needed */
}
</style>