    <template>
        <v-container fill-height fluid class="background-container">
        <v-row>
            <v-col>
                <div class="my-6 py-6"></div>
            </v-col>
        </v-row>
        <v-row align="stretch" justify="center" >
            <v-col cols="12" lg="3">
                <v-dialog v-model="dialog" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="$vuetify.breakpoint.mdAndDown" color="accent" v-bind="attrs" v-on="on">
                            <v-icon color="white">mdi-filter-outline</v-icon>
                            {{ $t('search.filter') }}
                        </v-btn>
                    </template>
                        <v-card v-if="$vuetify.breakpoint.mdAndDown" color="accent">
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ $t('search.filter')}}</v-toolbar-title>
                            </v-toolbar>
                            <v-divider></v-divider>
                            <v-card-text class="mt-2">
                                <v-container>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12">
                                            {{$t('search.brand')}}
                                            <v-select dark class="mt-4" color="background" rounded v-model="selectedBrands" :items="brands" item-text="name" item-value="id" :label="$t('home.Brands')"  outlined @change="applyBrand"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12">
                                            {{$t('search.type')}}
                                            <v-select class="mt-4" dark rounded color="background" v-model="selectedType" :items="types" item-text="name" item-value="id" :label="$t('home.Types')" outlined @change="applyType"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-col cols="12">
                                            {{$t('search.priceRange')}}
                                            <v-range-slider color="background" v-model="range" :max="max" :min="min" :menu-props="{ top:false, offsetY: false }" hide-details class="align-center mt-4" @change="changePrice">
                                                <template v-slot:prepend>
                                                    <v-chip color="background" @change="$set(range, 0, $event)">{{ range[0] }}</v-chip>
                                                </template>
                                                <template v-slot:append>
                                                    <v-chip color="background" @change="$set(range, 1, $event)">{{ range[1] }}</v-chip>
                                                </template>
                                            </v-range-slider>
                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-col>
                                            <v-btn color="background" class="white--text mb-8" @click="applyFilter">{{  $t('search.apply')  }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="dialog = false"
                                >Close</v-btn>
                            </v-card-actions>
                        </v-card> 
                </v-dialog>
                <v-card v-if="!$vuetify.breakpoint.mdAndDown" color="accent" class="ml-6">
                    <v-card-title><v-layout justify-center>{{$t('search.filter')}}</v-layout></v-card-title>
                    <v-divider></v-divider>
                    <v-layout justify-center class="mt-8 mx-4">{{$t('search.brand')}}</v-layout>
                    <v-select dark class="mt-8 mx-4" color="background" rounded v-model="selectedBrands" :items="brands" item-text="name" item-value="id" :label="$t('home.Brands')"  outlined @change="applyBrand"></v-select>
                    <v-layout class="mt-8 mx-4" justify-center>{{$t('search.type')}}</v-layout>
                    <v-select class="mt-8 mx-4" dark rounded color="background" v-model="selectedType" :items="types" item-text="name" item-value="id" :label="$t('home.Types')" outlined @change="applyType"></v-select>
                    <v-layout class="mt-8 mx-4" justify-center>{{$t('search.priceRange')}}</v-layout>
                    <v-range-slider color="background" v-model="range" :max="max" :min="min" :menu-props="{ top:false, offsetY: true }" hide-details class="align-center mt-8 mx-4" @change="changePrice">
                    <template v-slot:prepend>
                        <v-chip color="background" @change="$set(range, 0, $event)">{{ range[0] }}</v-chip>
                    </template>
                    <template v-slot:append>
                        <v-chip color="background" @change="$set(range, 1, $event)">{{ range[1] }}</v-chip>
                    </template>
                    </v-range-slider>
                    <v-layout class="mt-8 " justify-center>
                    <v-btn color="background" class="white--text mb-8" @click="applyFilter">{{  $t('search.apply')  }}</v-btn>
                    </v-layout>
                </v-card>
            </v-col>
    
            <v-col cols="12" sm="12" md="8" lg="9">
                <template>
                    <v-data-iterator color="accent" :items="items" :items-per-page.sync="itemsPerPage" :page.sync="page" :sort-by="sortBy.toLowerCase()" :sort-desc="sortDesc" hide-default-footer>
                        <template v-slot:header>
                        <v-toolbar color="accent" class="mb-1">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn dark text color="background" class="ml-2" v-bind="attrs" v-on="on">
                                    {{ itemsPerPage }}
                                    <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" @click="updateItemsPerPage(number)">
                                    <v-list-item-title>{{ number }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                                <v-spacer></v-spacer>
                                <v-select dark offset-y v-model="sortBy" color="background" outlined rounded flat hide-details :items="keys" :label="$t('search.sort')"></v-select>
                                <v-spacer></v-spacer>
                                <v-btn-toggle v-model="sortDesc" mandatory>
                                    <v-btn small fab color="accent" :value="false">
                                    <v-icon color="background">mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn small fab color="accent" :value="true">
                                    <v-icon color="background">mdi-arrow-down</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                        </v-toolbar>
                        </template>

                        <template v-slot:default="props">
                        <v-row>
                            <v-col v-for="item in props.items" :key="item.id" cols="6" sm="6" md="4" lg="3">
                            <ProductCard :sortBy="sortBy" :products="item" :filteredKeys="filteredKeys"/>
                            </v-col>
                        </v-row>
                        </template>

                        <template v-slot:footer>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12">                            
                            <span class="mr-4 grey--text">
                                {{ $t('search.pg') }} {{ page }} {{ $t('search.of') }} {{ numberOfPages }}
                            </span>
                            <v-layout justify-center>
                                <v-pagination
                                v-model="page"
                                :length="numberOfPages"
                                circle
                                color="accent"
                                navigation-color="background"
                                dark
                                ></v-pagination>
                            </v-layout>
                            </v-col>
                        </v-row>
                        </template>
                    </v-data-iterator>
                    </template>
            </v-col>
        </v-row>
    
        <v-row>
            <v-col>
            <div class="my-16 py-16"></div>
            </v-col>
        </v-row>
        </v-container>
    </template>
<script>
import axios from 'axios';
import ProductCard from "../components/productCard.vue";
export default {
    components: {
        ProductCard
    },
    data() {
        return {
            dialog: false,
            itemsPerPageArray: [4, 8, 12, 20, 24, 28],
            filter: {},
            sortDesc: false,
            page: 1,
            itemsPerPage: 8,
            sortBy: 'name',
            keys: ['Name', 'Price', 'Discount', 'Brand', 'Type'],
            items: [],
            min: 0,
            max: 90,
            range: [],
            brands: [],
            selectedBrands: null,
            types: [],
            selectedType: null,
            rangeQuery: null,
            typeQuery: null,
            brandQuery: null,
            nameQuery: null,
            numberOfPages: 0
        };
    },
    name: 'SearchPage',
    async mounted() {
        try {
            let brands = await axios.get(`search/getBrands`);
            this.brands = brands.data;
            let types = await axios.get(`search/getTypes`);
            this.types = types.data;
            let Price = await axios.get(`search/getPrice`);
            this.min = Price.data.minPrice;
            this.max = Price.data.maxPrice;
            this.range = [this.min, this.max];
            if(this.$route.query.type){
                this.typeQuery =  { typeID: this.$route.query.type };
            }else if(this.$route.query.brand){
                this.brandQuery =  { brandID: this.$route.query.brand };
            }else if(this.$route.query.name){
                this.nameQuery = { name: this.$route.query.name };
            }
            this.initialize();
        } catch (e) {
            this.$root.$emit("show_error_alert", e.message);
        }
    },
    computed: {
        filteredKeys() {
        return this.keys.filter(key => key !== 'Name');
        },
    },
    watch: {
        page: 'fetchData',
        itemsPerPage: 'fetchData'
    },
    methods: {
        applyBrand() {
            this.brandQuery = { brandID: this.selectedBrands };
        },
        applyType() {
            this.typeQuery = { typeID: this.selectedType };
        },
        changePrice() {
            this.rangeQuery = { priceMin: this.range[0], priceMax: this.range[1] };
        },
        async applyFilter() {
            await this.fetchData();
            this.page = 1;
        },
        async fetchData() {
            let query = {...this.nameQuery ,...this.brandQuery, ...this.typeQuery, ...this.rangeQuery, page: this.page, pageSize: this.itemsPerPage };
            
            let queryString = Object.keys(query).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`).join('&');
            
            try {
                let response = await axios.get(`search/searchProd?${queryString}`);
                console.log(response)
                this.items = response.data.products.map(prod => ({
                    id: prod.id,
                    name: prod.name,
                    price: prod.price,
                    description: prod.description,
                    quantity: prod.quantity,
                    discount: prod.discount,
                    pic: prod.pic,
                    createdAt: prod.createdAt,
                    updatedAt: prod.updatedAt,
                    brandName: prod.brand ? prod.brand.name : '',
                    brand: prod.brand ? prod.brand.name : '',
                    type: prod.product_type ? prod.product_type.name : '',
                    typeID: prod.product_type ? prod.product_type.id : '',
                    out_of_stock: prod.out_of_stock ? prod.out_of_stock : '',
                }));
                this.numberOfPages = response.data.totalPages;
            } catch (e) {
                this.$root.$emit("show_error_alert", e.message);
            }
        },
        async initialize() {
            await this.fetchData();
        },
    },
};
</script>
<style >
.theme--dark.v-pagination .v-pagination__item {
    background:gold;
    color: rgba(255, 255, 255, 0.87);
}
.theme--light.v-pagination .v-pagination__item {
    background:gold;
    color: rgba(255, 255, 255, 0.87);
}
</style>