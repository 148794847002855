<template>
    <div class="d-flex justify-end background-container">
        <v-alert 
            class="my-alert text-right"
            type="info"
            v-if="show"
            transition="scale-transition"
            dismissible
            color="cyan"
            border="top"
            elevation="2"
            :style="alertStyle"
        >
            {{inf_msg}}
        </v-alert>
    </div>
</template>
<script>
export default {
        name:'InformationMsg',
        props:['inf_msg'],
        data: () => ({
            show :false,
            alertVisible: false,
            scrollY: 0,
        }),
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        computed: {
            alertStyle() {
                return {
                    position: 'fixed',
                    top: `${this.scrollY + 5}px`, // Adjust the offset as needed
                    // left: '0%',
                    // transform: 'translateX(-50%)',
                    zIndex: 1000, // Ensure it's above other content
                };
            },
        },
        methods:{
            showAlert(){
                this.show = true
                setTimeout(()=>{
                    this.show = false
                },5000)
            },
            handleScroll() {
                this.scrollY = window.scrollY;
            },
        }
    }
</script>  