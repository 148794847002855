import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/axios'
import i18n from '@/i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  let language = to.params.lang;

  if(!language){
    language = 'fr';
  }

  i18n.locale = language;

  next();
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
